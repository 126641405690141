import React from "react";
import styles from './Spinners.module.css';
import { ReactComponent as Logo} from '../../imgs/vP_01.svg';
import SpinnerSVG from "./SpinnerSVG";

export default function Spinners({data}){
    const {topText1,topText2,bottomText1,bottomText2,imgNo} = data
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <h6>{topText1}<br/>{topText2}</h6>
            </div>
            <div className={styles.spinnerContainer}>
                <SpinnerSVG imgNo={imgNo}/>
            </div>
            <div className={styles.bottom}>
                <h6>{bottomText1}<br/>{bottomText2}</h6>
            </div>
        </div>
    )
}