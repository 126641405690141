import React from "react";
import styles from './Footer.module.css';
import {ReactComponent as InstaLogo} from '../../imgs/Instagram_Glyph_White.svg';
import { ReactComponent as PhoneIcon } from '../../imgs/designStrat/phone.svg';
import { ReactComponent as EmailIcon } from '../../imgs/designStrat/email.svg';
import { ReactComponent as Circle } from '../../imgs/Clipping_circle_mask.svg'
import GradientButton from "./GradientButton";
import SLogo from "./SLogo";
import SLogoAnimated from "./SLogoAnimated";
// import { useNavigate } from "react-router-dom";

export default function Footer({navigateToElement}){

    const navigateAboutUs = () => {
        navigateToElement('/home','aboutUsSection');
    }

    const navigateContact = () => {
        window.scrollTo(0,0);
        navigateToElement('/contact','');
    }

    return (
        <footer>
            <div>
                <h2>Use our imagination</h2>
                <h2 className={styles.italicHeader}>Let's talk.</h2>
                <GradientButton navigateContact={navigateContact} />
            </div>
            <div className={styles.footerGrid}>
                <div className={styles.detailsDiv}>
                    <div styles={styles.contactDetails}>
                        <div className={styles.iconTextCon}>
                            <div className={styles.iconCon}>
                                <PhoneIcon />
                            </div>
                            <p>+44 (0) 7813086642</p>
                        </div>
                        <div className={styles.iconTextCon}>
                            <div className={styles.iconCon}>
                                <EmailIcon />
                            </div>
                            <p>info@stratela.co.uk</p>
                        </div>
                    </div>
                    <div className={styles.address}>
                        <div className={styles.headingIconCon}>
                            <div className={styles.headingIcon}>
                                <Circle className={styles.circleDesign}/>
                            </div>
                            <h6 style={{lineHeight: 1}} className={styles.dsHeading}>Design Studio</h6>
                        </div>
                        
                        <p >Sandy Crest, Millfield Lane,<br/> Tadworth, Surrey<br/>KT20 6RT<br/>United Kingdom</p>
                    </div>
                    <div className={styles.address}>
                        <div className={styles.headingIconCon}>
                            <div className={styles.headingIcon}>
                                <Circle className={styles.circleReg}/>
                            </div>
                            <h6 style={{lineHeight: 1}} className={styles.regHeading}>Registered Address</h6>
                        </div>
                        <p className={styles.grey}>124 City Road,<br/>London,<br/>EC1V 2NX<br/>United Kingdom</p>
                        {/* <div className={styles.logoContainer}>
                            <InstaLogo className={styles.instaLogo}/>
                            <img src={require('../../imgs/Facebook_Logo_Secondary.png')} />
                            <img src={require('../../imgs/linkedin-app-white-icon.webp')} />
                        </div> */}
                    </div>
                </div>
                <div className={styles.sLogoContainer}>
                    <SLogo/>
                </div>
            </div>
            <div className={styles.footerFooter}>
            <img src={require('../../imgs/linkedin-app-white-icon.webp')} />
            © Stratela Ltd 2024
            </div>
        </footer>
    )
}