import React, { useEffect, useState } from "react";
import styles from './Home.module.css';
import { useOutletContext } from "react-router-dom";
import Spinners from "./Spinners";
import HoverTextPara from "./HoverTextPara";
import ImageSwitcher from "./ImageSwitcher";
import { ReactComponent as Logo } from '../../imgs/designStrat/stratNew.svg';

export default function Home(){

    useEffect(() => {
        window.scrollTo(0,0);
    },[]);

    const {mobileVersion} = useOutletContext();

    const [headingStyles,setHeadingStyles] = useState({
        top: (window.innerHeight/2) - (7.875*16),
        opacity: 1
    });
    const [aboutUsSectionImgStyles,setAboutUsSectionImgStyles] = useState({
        top: 0
    });
    const [aboutUsSectionTextStyles,setAboutUsSectionTextStyles] = useState({
        top: 0,
        opacity: 1
    });
    const [visionSectionImgStyles,setVisionSectionImgStyles] = useState({
        top: 0
    });
    const [visionSectionTextStyles,setVisionSectionTextStyles] = useState({
        top: 0,
        opacity: 1
    });
    const [buildManageSectionImgStyles,setBuildManageSectionImgStyles] = useState({
        top: 0
    });
    const [buildManageSectionTextStyles,setBuildManageSectionTextStyles] = useState({
        top: 0,
        opacity: 1
    });
    const [ourServicesSectionTextStyles,setOurServicesSectionTextStyles] = useState({
        top: 0,
        opacity: 1
    });
    const [designStrategySectionTextStyles,setDesignStrategySectionTextStyles] = useState({
        top: 0,
        opacity: 1
    });
    const [ourServicesOverlayOpacity,setOurServicesOverlayOpacity] = useState({
        opacity: 1
    });
    const [openService,setOpenService] = useState('');

    const openServiceObj = {
        openService,
        setOpenService
    };

    useEffect(() => {
        servicesFunctionOnly('ourServicesSection',setOurServicesSectionTextStyles);
    },[openService]);


    const titleHeadingScrollFunction = () => {
        const headingDefaultTop = (window.innerHeight/2) - (7.875*16);
        const newHeadingTop = headingDefaultTop - (window.scrollY/1.5);
        let opacity = newHeadingTop/headingDefaultTop;
        if(newHeadingTop < 0){
            opacity = 0;
        };
        setHeadingStyles({
            top: newHeadingTop,
            opacity: opacity
        });
    }

    const imgScrollFunctionNew = (parentElementId,setterFunction,mobile) => {
        const element = document.getElementById(parentElementId).getBoundingClientRect();
    
        const viewportHeight = window.innerHeight;

        let topPosition;

        if(!mobile){
            if (element.top >= viewportHeight){
                // start position
                topPosition = 0;
            } else if (element.bottom <= 0) {
                // end position
                topPosition = -0.25*viewportHeight;
            } else {
                topPosition = viewportHeight*((element.top/viewportHeight)-1)/8;
            }
        } else {
            if (element.top >= viewportHeight){
                // start position
                topPosition = 0;
            } else if (element.bottom <= 0) {
                // end position
                topPosition = -0.125*viewportHeight;
            } else {
                topPosition = viewportHeight*((element.top/viewportHeight)-1)/16;
            }
        }
        setterFunction({
            top: topPosition
        });
    }

    const textScrollFunctionNew = (parentElementId,setterFunction) => {
        const element = document.getElementById(parentElementId).getBoundingClientRect();
    
        const viewportHeight = window.innerHeight;

        let topPosition;
        if (element.top >= viewportHeight){
            // start position
            topPosition = 0;
        } else if (element.bottom <= 0) {
            // end position
            topPosition = -0.5*viewportHeight;
        } else {
            topPosition = viewportHeight*((element.top/viewportHeight)-1)/4;
        }

        let opacity;
        if(element.top >= viewportHeight){
            opacity = 0;
        } else if (element.bottom <= 0) {
            opacity = 0;
        } else if (element.top <= viewportHeight*0.25 && element.top >= viewportHeight*(-0.25)){
            opacity = 1;
        } else if (element.top > viewportHeight*0.25 && element.top < viewportHeight){
            opacity = (4/3)*(1-(element.top/viewportHeight));
        } else if (element.top < viewportHeight*(-0.25) && element.bottom > 0){
            opacity = (4/3)*(1+(element.top/viewportHeight));
        }
        // console.log(`opacity: `+opacity);
        setterFunction({
            top: topPosition,
            opacity
        });

    }

    const servicesFunctionOnly = (parentElementId,setterFunction) => {
        const element = document.getElementById(parentElementId).getBoundingClientRect();
    
        const viewportHeight = window.innerHeight;

        let topPosition;
        if (element.top >= viewportHeight){
            // start position
            topPosition = 0;
        } else if (element.bottom <= 0) {
            // end position
            topPosition = -0.5*viewportHeight;
        } else {
            topPosition = viewportHeight*((element.top/viewportHeight)-1)/4;
        }

        let opacity;
        if(element.top >= viewportHeight){
            opacity = 0;
        } else if (element.bottom <= 0) {
            opacity = 0;
        } else if (element.top <= viewportHeight*0.5 && element.bottom >= viewportHeight*(0.15)){
            opacity = 1;
        } else if (element.top > viewportHeight*0.5 && element.top < viewportHeight){
            opacity = 0;
        } else if (element.top < viewportHeight*(-0.25) && element.bottom > 0){
            opacity = (4/3)*(1+(element.top/viewportHeight));
        }
        // console.log(`opacity: `+opacity);
        setterFunction({
            top: topPosition,
            opacity
        });

    }

    useEffect(() => {
        if(ourServicesSectionTextStyles.opacity > 0.99){
            setOurServicesOverlayOpacity({opacity: 1});
        } else {
            setOurServicesOverlayOpacity({opacity: 0});
        }
    },[ourServicesSectionTextStyles.opacity]);

    const onScrollFunction = () => {
        titleHeadingScrollFunction();
        textScrollFunctionNew('aboutUsSection',setAboutUsSectionTextStyles);
        imgScrollFunctionNew('aboutUsSection',setAboutUsSectionImgStyles,mobileVersion);
        textScrollFunctionNew('designStrategySection',setDesignStrategySectionTextStyles);
        textScrollFunctionNew('visionSection',setVisionSectionTextStyles);
        imgScrollFunctionNew('visionSection',setVisionSectionImgStyles,mobileVersion);
        textScrollFunctionNew('buildManageSection',setBuildManageSectionTextStyles);
        imgScrollFunctionNew('buildManageSection',setBuildManageSectionImgStyles,mobileVersion);
        servicesFunctionOnly('ourServicesSection',setOurServicesSectionTextStyles);
    }

    useEffect(() => {
        window.addEventListener('scroll', onScrollFunction);
        return () => {
            window.removeEventListener('scroll', onScrollFunction);
        }
    },[]);

    class SpinnerObj {
        constructor(topText1,topText2,bottomText1,bottomText2,imgNo){
            this.topText1 = topText1;
            this.topText2 = topText2;
            this.bottomText1 = bottomText1;
            this.bottomText2 = bottomText2;
            this.imgNo = imgNo;
        }
    };

    const spinnersArray = [
        new SpinnerObj('Think wide','& far','Innovate &','create change',"1"),
        new SpinnerObj('Always learn','& Improve','Aim for better','',"2"),
        new SpinnerObj('Listen','& respond','Show honesty','& respect',"3"),
        new SpinnerObj('Love','the detail','Create WOW','',"4")
    ];

    class ServicesItems {
        constructor(id,headingText,bodyText){
            this.id = id;
            this.headingText = headingText;
            this.bodyText = bodyText;
        }
    }

    const servicesArray = [
        new ServicesItems('service1','Brand Identity','We help to create brands that deliver the essence of your product or service. We offer support in logo design, brand positioning, tone of voice and everything else needed to help you resonate with your audience.'),
        new ServicesItems('service2','Brand Management & Implementation','Good brand management is at the core of our focus. We embody your brand through immersing ourselves in everything you do, working closely with your team to drive both consistency and distinction in every application.'),
        new ServicesItems('service3','Signage & Environmental Graphics','We understand the importance and balance of well executed signage. Our team is adept at understanding the priorities of brand presence over budget and work with sites and suppliers to ensure each scheme is executed in detail and to the specific needs of each site.'),
        new ServicesItems('service4','Project Management','Our focus is not only on the perfect scheme but the perfect delivery of each project phase from start to finish. We collaborate with our suppliers to make sure all elements of each project are thoroughly assessed and managed with continuous feedback to all key stakeholders.'),
        new ServicesItems('service5','Exhibition Design','When it comes to exhibitions its important that your experience is both true to your brand but also unique. Our aim is to create unique experiences that help your brand resonate with your audience and rise above the competition, whilst considering the importance of re-use and sustainability at all times.Our experienced designers work with you and your team throughout the design, build, delivery and installation phases to ensure your event is a success.'),
        new ServicesItems('service6','Animation Design','Animated video content allows you to get your message across in a short space of time with high levels of customer engagement. We offer services from highly visual motion graphics to creating fully immersive 3D animated scenes.'),
        new ServicesItems('service7','Interior & Architectural Design','We design, manage and fuse both structural and interior design in one streamlined process in house. This continuity, and by combining our efforts and knowledge, allows us to deliver more cohesive, and holistic spatial solutions at a higher calibre.'),
        new ServicesItems('service8','Industrial Product Design','We dedicate our time to our clients to understand their vision, thoroughly define the brief or underpin the problem at its source, to deliver considered and well executed products by blending creative vision and technical know-how with our own twist.')
    ];

    const buildImgArray = [{img: './imgs/initialImages/guyWork1-PS-LR.jpg', pos: '100% 0%'},'./imgs/initialImages/sarahDesk1-PS-LR.jpg','./imgs/AdobeStock_340996520-scaled-1-2048x1152.jpeg'];
    const aboutUsImgArray = [{img: './imgs/initialImages/IMG_1627-PS-LS.jpg', pos: '80% 50%'},{img: './imgs/initialImages/TreeHouse2-PS-LR.jpg', pos: 'center'},'./imgs/initialImages/houseFront1-PS-LR.jpg',{img: './imgs/office_and_externals/deer3comp.jpg', pos: 'center'}];
    const visionImgArray = ['./imgs/office_and_externals/treeLeftRoot1comp.JPG','./imgs/initialImages/houseLeaves1-PS-LR.jpg'];

    return (
        <main>
            <section className={styles.landingSection}>
                <video id="s_intro" autoPlay muted loop playsInline style={{pointerEvents: "none"}}>
                    <source src={require('./../../vids/S-Intro.mp4')} type="video/mp4"/>
                </video>
                <h1 id="titleText" style={{top: headingStyles.top, opacity: headingStyles.opacity}}>Design<br/>& Strategy<br/>Agency</h1>
            </section>
            <section id="buildManageSection" className={`${styles.aboutUsSection} ${styles.buildManageSection}`} >
                <div style={{top: buildManageSectionTextStyles.top, opacity: buildManageSectionTextStyles.opacity}} className={`${styles.sectionText} ${styles.sectionTextBlack}`}>
                    {/* <h2>We <span className={styles.buildColor}>Build</span>, <br/><span className={styles.manageColor}>Manage</span> & <br/><span className={styles.implementColor}>Implement</span><br/>Brands</h2> */}
                    <h2>We <span>Build</span>, <br/><span>Manage</span> & <br/><span>Implement</span><br/>Brands</h2>
                    <h6>For clients and creative partners through the delivery of both physical and digital connected brand experiences.</h6>
                </div>
                <div className={`${styles.circleMask}`}>
                    <ImageSwitcher imgPathArray={buildImgArray} stateValue={buildManageSectionImgStyles} specificStyle={styles.sectionImgBuildManage}/>
                </div>
            </section>
            <section id="designStrategySection" className={`${styles.designStrategySection}`}>
                <div className={styles.designStrategyImgCon}>
                    <div className={styles.designStrategyBlur}></div>
                    <Logo />
                </div>
                <div className={styles.designStrategyTextCon}>
                    <div style={{top: designStrategySectionTextStyles.top, opacity: designStrategySectionTextStyles.opacity}} className={`${styles.sectionText} ${styles.sectionTextRight} ${styles.sectionTextBlack}`}>
                        <h2>Design<br/>& Strategy</h2>
                        <h5>We challenge the status quo.</h5>
                        <h6 className={styles.thin}>Aligning design decisions to customer needs whilst also considering business aspirations, stakeholders and user needs, enables us to undertake more effective and efficient product development.</h6>
                    </div>
                </div>
            </section>
            <section id="ourServicesSection" className={`${styles.aboutUsSection} ${styles.ourServicesSection} ${openService? '' : styles.ourServicesSectionBgImg}`}>
                <div className={`${styles.ourServicesOverlay} ${openService !== ''? styles.darkOverlay : ''}`} style={{opacity: ourServicesOverlayOpacity.opacity}}></div>
                    <div className={styles.ourServicesTextCon} style={{opacity: ourServicesSectionTextStyles.opacity}}>
                        <div style={{top: ourServicesSectionTextStyles.top}} className={styles.sectionText}>
                            <h2>Our<br /> Services</h2>
                        </div>
                        <div className={styles.servicesOptions}>
                            {servicesArray.map((data) => <HoverTextPara mobileVersion={mobileVersion} data={data} openServiceObj={openServiceObj}/>)}
                        </div>
                    </div>
                
            </section>
            <section id="aboutUsSection" className={styles.aboutUsSection}>
                <div style={{top: aboutUsSectionTextStyles.top, opacity: aboutUsSectionTextStyles.opacity}} className={`${styles.sectionText} ${styles.sectionTextBlack}`}>
                    <h2>About Us</h2>
                    <h5>We are a passionate and creative design agency.</h5>
                    <h6 className={styles.thin}>In today’s competitive market, having a strong brand identity is crucial for any business. At Stratela, we understand the importance of creating a unique and memorable brand that resonates with its target audience.</h6>
                </div>
                <div className={`${styles.circleMask}`}>
                    <ImageSwitcher imgPathArray={aboutUsImgArray} stateValue={aboutUsSectionImgStyles}/>
                </div>
            </section>
            <section id="valuesSection" className={styles.valuesSection}>
                <div className={styles.valuesSectionHeadingCon}>
                    <h2>Values & Principles</h2>
                </div>
                <div className={styles.spinners}>
                    {spinnersArray.map((data) => <Spinners data={data}/>)}
                </div>
                <div className={styles.valuesSectionParaCon}>
                    <h6 className={styles.thin}>Client openness and transparency are values that we take into every project we work on.
                    At the heart of this is our belief in building strong relationships with our clients through surpassing expectations and effective communication.</h6>                    
                </div>
            </section>
            {/* <section id="visionSection" className={styles.visionSection}>
                <div style={{top: visionSectionTextStyles.top, opacity: visionSectionTextStyles.opacity}} className={styles.sectionText}>
                    <h2>Vision & Purpose​</h2>
                    <h5>We design because we believe in progress and positive change.</h5>
                    <h6 className={styles.thin}>We seek innovation through a human centred approach to aesthetics and function. With every problem comes an array of solutions. We support our clients through identifying and enacting these solutions and share in their growth and progress.</h6>
                </div>
                <div className={styles.circleMask}>
                    <ImageSwitcher imgPathArray={visionImgArray} stateValue={visionSectionImgStyles} specificStyle={styles.sectionImgVision}/>
                </div>
            </section> */}
            <section id="visionSection" className={`${styles.designStrategySection}`}>
                <div className={`${styles.basicMask}`}>
                    <ImageSwitcher imgPathArray={visionImgArray} stateValue={visionSectionImgStyles} specificStyle={styles.sectionImgVision}/>
                </div>
                <div className={styles.designStrategyTextCon}>
                    <div style={{top: visionSectionTextStyles.top, opacity: visionSectionTextStyles.opacity}} className={`${styles.sectionText} ${styles.sectionTextRight} ${styles.sectionTextBlack}`}>
                        <h2>Vision & Purpose​</h2>
                        <h5>We design because we believe in progress and positive change.</h5>
                        <h6 className={styles.thin}>We seek innovation through a human centred approach to aesthetics and function. With every problem comes an array of solutions. We support our clients through identifying and enacting these solutions and share in their growth and progress.</h6>
                    </div>
                </div>
            </section>
        </main>
    )
}