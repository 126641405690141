import React from "react";
import styles from './GradientMaskObjects.module.css';

export default function SLogo(){
    return (
        <div className={styles.outerCon}>
            <div className={styles.middleCon}>
                <div className={styles.sLogoMask}>
                    <img className={styles.sAnimation} src={require('../../imgs/bg_mask_gradient-2048x1152.jpg')}/>
                </div>
            </div>
        </div>
    );
}