import React , { useEffect, useState } from "react";
import styles from './BannerReveal.module.css';

export default function BannerReveal({id,selectedState,img1,img2}){
    const [reveal,setReveal] = useState(50);
    useEffect(() => {
        const sliderElement = document.getElementById(`carouselRevealElement${id}`);
        
        let sliderPressed = false;
        const mouseMovefunction = (e) => {
            const container = document.getElementById(`carouselRevealElement${id}`);
            if(sliderPressed){
                if(e.type === 'mousemove'){
                    e.preventDefault();
                    setReveal(revealLimiter(((e.clientX-container.getBoundingClientRect().left)/(container.offsetWidth))*100));
                } else if (e.type === 'touchmove'){
                    const { touches, changedTouches } = e.originalEvent ?? e;
                    var touch = touches[0] || changedTouches[0];
                    setReveal(revealLimiter(((touch.pageX-container.getBoundingClientRect().left)/container.offsetWidth)*100));
                }
            }
        }
    
        const onSliderMouseDown = (e) => {
            if(e.type === 'mousedown'){
                e.preventDefault();
            }
            sliderPressed = true;
        }
    
        const onSliderMouseUp = () => {
            sliderPressed = false;
        }

        const revealLimiter = (reveal) => {
            if(reveal < 0){
                return 0;
            } else if (reveal > 100) {
                return 100;
            } else {
                return reveal;
            }
        }

        sliderElement.addEventListener('mousedown',onSliderMouseDown);
        sliderElement.addEventListener('mouseup',onSliderMouseUp);
        sliderElement.addEventListener('mousemove',mouseMovefunction);

        sliderElement.addEventListener('touchstart',onSliderMouseDown);
        sliderElement.addEventListener('touchend',onSliderMouseUp);
        sliderElement.addEventListener('touchmove',mouseMovefunction);

        return () => {
            sliderElement.removeEventListener('mousedown',onSliderMouseDown);
            sliderElement.removeEventListener('mouseup',onSliderMouseUp);
            sliderElement.removeEventListener('mousemove',mouseMovefunction);

            sliderElement.removeEventListener('touchstart',onSliderMouseDown);
            sliderElement.removeEventListener('touchend',onSliderMouseUp);
            sliderElement.removeEventListener('touchmove',mouseMovefunction);
        }
    },[id]);

    useEffect(() => {
        if(selectedState){
            setReveal(50);
        }
    },[selectedState])

    return(
        <div id={`carouselRevealElement${id}`} className={styles.container}>
            <div className={styles.bannerOuter} style={{backgroundImage: `url(${img2})`}}>
                <div className={styles.bannerInner} style={{backgroundImage: `url(${img1})`, maskImage: `linear-gradient(to right, black ${reveal}%, transparent 0%)`}}>
                </div>
            </div>
            <div className={styles.slider} style={{left: `calc(${reveal}% - 1rem)`}}>
                <div className={styles.sliderCircle}>||</div>
                <div className={styles.sliderInside}></div>
            </div>
        </div>
    )
}
//<p>&#60; &#62;</p>