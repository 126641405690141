import React from "react";
import styles from './GradientMaskObjects.module.css';

export default function GradientButton({navigateContact}){
    return (
        <button onClick={navigateContact} className={styles.footerButtonContainer}>
            <div className={styles.buttonOverlay}>

            </div>
            <p className={styles.dummy}>Let's discuss your project</p>
            <p className={styles.real}>Let's discuss your project</p>
            <div className={styles.footerButton}>
                <img className={styles.buttonAnimation} src={require('../../imgs/bg_mask_gradient-2048x1152.jpg')}/>
            </div>
        </button>
    );
}