import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import styles from './HoverTextPara.module.css';
import { ReactComponent as Cross } from '../../imgs/icons/cross-33.svg';
import { ReactComponent as Plus } from '../../imgs/icons/plus-34.svg';
import { ReactComponent as Minus } from '../../imgs/icons/minus-35-36.svg';

export default function HoverTextPara({mobileVersion,data,openServiceObj}) {
    const {id,headingText,bodyText} = data;

    const thisElement = id;

    const [active,setActive] = useState(false);
    const [textHeight,setTextHeight] = useState(0);

    useEffect(() => {
        setTextHeight(document.getElementById(`text${id}`).offsetHeight);
    },[active]);

    useEffect(() => {
        if(openServiceObj.openService === thisElement){
            setActive(true);
        } else {
            setActive(false);
        }
    },[openServiceObj.openService]);
    
    const activeTrue = () => {
        if(!mobileVersion){
            openServiceObj.setOpenService(thisElement);
        }
    }

    const activefalse = () => {
        if(!mobileVersion){
            openServiceObj.setOpenService('');
        }
    }

    const toggleActive = () => {
        openServiceObj.setOpenService((prev) => {
            if(prev === thisElement){
                return '';
            } else {
                return thisElement;
            }
        });
    }
    return (
        <div onMouseEnter={activeTrue} onClick={toggleActive} onMouseLeave={activefalse} className={`${styles.container} ${active? styles.active : ''}`}>
            <div className={`${styles.headingContainer} ${active? styles.activeHeadingContainer : ''} ${openServiceObj.openService !== thisElement && openServiceObj.openService !== ''? styles.otherActive : ''}`}>
                <h4 className={`${styles.heading} ${active? styles.activeHeading : styles.inActiveHeading} ${openServiceObj.openService !== thisElement && openServiceObj.openService !== ''? styles.otherActive : ''}`}>{headingText}</h4>
                <div className={styles.iconCon}>
                    <div className={`${styles.icon} ${active? styles.activeIcon : styles.inActiveIcon} ${openServiceObj.openService !== thisElement && openServiceObj.openService !== ''? styles.otherActiveIcon : ''}`}>
                        {active? <Minus /> : <Plus />}
                    </div>
                </div>
            </div>
            <div style={{height: `${active? textHeight : 0}px`}} className={`${styles.pane}`}>
                <p id={`text${id}`}>{bodyText}</p>
            </div>
        </div>
    )

}