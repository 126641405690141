import React, { useEffect, useState } from "react";
import styles from './BannerSwitcher.module.css';

export default function BannerSwitcher({imgPathArray}){

    const length = imgPathArray.length;

    const [bannerImg,setBannerImg] = useState(0);

    useEffect(() => {
        let interval;
        interval = setInterval(() => {
            setBannerImg((prev) => {
                if(prev < length - 1){
                    return Number(prev)+1;
                } else {
                    return 0;
                }
            })
        },5000);
        return () => {
            clearInterval(interval);
        }
    },[imgPathArray]);

    let i = 0;

    return (
        <>
            {imgPathArray.map((imgPath) => {
                let j = i;
                i++;
                return <div className={styles.banner} style={{opacity: `${bannerImg === j? 1 : 0}`, backgroundImage: `url(${imgPath})`}}></div>
            })}
           
        </>
    )
}