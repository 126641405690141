import React, { useEffect, useState } from "react";
import { ReactComponent as Spin1} from '../../imgs/vP_01.svg';
import { ReactComponent as Spin2} from '../../imgs/vP_02.svg';
import { ReactComponent as Spin3} from '../../imgs/vP_03.svg';
import { ReactComponent as Spin4} from '../../imgs/vP_04.svg';
import styles from './Spinners.module.css';

export default function SpinnerSVG({imgNo}) {
        
        const [spinAmt,setSpinAmt] = useState('0');

        const spinFunction = () => {
            const circle = document.getElementById(`circle${imgNo}`).getBoundingClientRect();
            const viewportHeight = window.innerHeight;

            let spin;
            if(circle.top <= viewportHeight*0.2){
                spin = 60*(1-(circle.top/(viewportHeight*0.2)));
            } else if (circle.bottom >= viewportHeight*0.8) {
                spin = -60*((circle.bottom/(viewportHeight*0.2))-1) -180;
            } else if (circle.top >= viewportHeight*0.2 && circle.bottom <= viewportHeight*(0.8)){
                spin = 0;
            } // else if (circle.top > viewportHeight*0.25 && circle.top < viewportHeight){
            //     spin = (4/3)*(1-(circle.top/viewportHeight));
            // } else if (circle.top < viewportHeight*(-0.25) && circle.bottom > 0){
            //     spin = (4/3)*(1+(circle.top/viewportHeight));
            // }
            setSpinAmt(spin)
        }

        useEffect(() => {
            window.addEventListener('scroll', spinFunction);
            return () => {
                window.removeEventListener('scroll', spinFunction);
            }
        },[]);
        const spinners = () => {
            switch(imgNo){
                case '1':
                    return <Spin1/>;
                case '2':
                    return <Spin2/>;
                case '3':
                    return <Spin3/>;
                case '4':
                    return <Spin4/>;
            }
        }
        return(
            <div id={`circle${imgNo}`} className={styles.circles} style={{transform: `rotate(${spinAmt}deg)`}}>{spinners()}</div>
        )
    
    
}